import * as React from 'react';
import {
  TextField,
  Link,
  Grid,
  Box,
  Button
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import Alerts from '../../components/Alert/Alert';
export default function SignIn(props){
  const {user_detail}=props
const [values, setValues] = React.useState({
  data: {
      email: '',
      password: '',
  },
  required: {
      email: '',
      password: '',
  }
})

const handleChange = (prop) => (event) => {

  var validData = "success";
  let required = values.required;
  let data = values.data
  required[prop] = validData
  data[prop] = event.target.value

      setValues({
          ...values,
          ['data']: data,
          ['required']: required
      });
};

const onLoginClick = () => {
  if(validation()){
  props.signInUser(values.data)
  }
}

const validation = () => {
  var reqData = values['required'];
  var isValid = true;

  if (!values.data.hasOwnProperty("email") || (values.data.email.trim() === "" || reqData["email"] === "error")) {
      reqData["email"] = "error";
      isValid = false;
  }

  if (!values.data.hasOwnProperty("password") || (values.data.password.trim() === "" || reqData["password"] === "error")) {
      reqData["password"] = "error";
      isValid = false;
  }

  setValues({
      ...values,
      'required': reqData
  });
  return isValid;
}

  return (
        <Grid container direction='row'>
              <Grid item xs={12}>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs style={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                          <img src="/logo.png" style={{height:'200px',width:'200px'}} alt='logo'/>
                          <Box component="form"  noValidate sx={{ mt: 1 }}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email "
                              name="email"
                              value={values.data.email}
                              error={values.required.email === "error"}
                              onChange={handleChange('email')}
                            />
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              value={values.data.password}
                              error={values.required.password === "error"}
                              onChange={handleChange('password')}
                            />
                           { user_detail.error!==null&& <Alerts type="error" message={user_detail.error}/>}
                            <Button
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={()=>onLoginClick()}
                            >
                              Sign In
                            </Button>
                          </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
  );
}