import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import * as routesNames from '../../constants/routes'

import {
  Login,
  Dashboard,
  HomePage,
  Registration,
  Offers
} from '..'
const Routes = (
  <Switch>
    <Route exact path={routesNames.LOGIN} component={Login} />
    <Route exact path={routesNames.DASHBOARD} component={Dashboard} />
    <Route exact path={routesNames.HOMEPAGE} component={HomePage} />
    <Route exact path={routesNames.REGISTRATION} component={Registration} />
    <Route exact path={routesNames.OFFER} component={Offers} />
  </Switch>
)

export default Routes
