import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles } from '@material-ui/core';
import FooterStyled from './footer.styles';
const useStyles = makeStyles((theme) => ({
  footerText: {
    color: "#fff"
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: "#cacaca"
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      {new Date().getFullYear()}
    </Typography>
  );
}


export default function Footer(props) {
  const classes = useStyles();
  return (
    <FooterStyled className={classes.footer}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Copyright />
      </Grid>
     
    </FooterStyled >
  );
}