import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function AddressForm(props) {
  const {data,required}=props.data
  const _handleChange = (prop) => (event) => {
    props.handleChange(prop,event.target.value)
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField   
            id="city"
            name="City"
            label="City"
            fullWidth
            variant="outlined"
            value={data.address.city}
            error={required.address.city=== "error"}
            onChange={_handleChange('city')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="Country"
            label="Country"
            fullWidth
            variant="outlined"
            value={data.address.country}
            error={required.address.country=== "error"}
            onChange={_handleChange('country')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="line1"
            name="line1"
            label="Address line 1"
            fullWidth
            variant="outlined"
            value={data.address.line_1}
            error={required.address.line_1=== "error"}
            onChange={_handleChange('line1')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="line2"
            name="line2"
            label="Address line 2"
            fullWidth
            variant="outlined"
            value={data.address.line_2}
            error={required.address.line_2=== "error"}
            onChange={_handleChange('line2')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zipcode"
            name="zipcode"
            label="Zip / Postal code"
            fullWidth
            variant="outlined"
            value={data.address.postal_code}
            error={required.address.postal_code==="error"}
            onChange={_handleChange('zipcode')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}