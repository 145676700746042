import  React,{useState,useEffect} from 'react';
import {Container,Grid,Button} from '@mui/material';
import Offers from '../form/Offers'
import Dialog from '../components/Dialog';
import moment from 'moment';
import { ActionCreators } from '../redux/actions'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Offer from '../list/Offer';
import {Edit,Add} from '@mui/icons-material';
import Switch from '@mui/material/Switch';

const OffersPages= (props) => {

  const renderActions = (value) => (
    <Grid
      container
      wrap='nowrap'
      alignItems='center'
    >
    <Grid item style={{ marginTop: -2 }}>
        <Edit  onClick={() =>{handleEdit(value)}} sx={{fontSize:'20px'}}/>
      </Grid> 
      <Grid item style={{ marginTop: -2 }}>
      
          </Grid>
    </Grid>
  )
const handleChangeActive=(status,value)=>{
  let data={};
  data.id=value.rowData[0];
  data.is_active=status?0:1;
  props.actions.offersSave(data,'edit')
}
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display:false,
       filter: true,
       sort: true,
      }
     },
    {
     name: "name",
     label: "Name",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "apr",
     label: "APR",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "loan_term",
     label: "Loan Term",
     options: {
      filter: true,
      sort: false,
     }
    },
     {
      name: "is_active",
      label: "Active",
      options: {
       filter: true,
       sort: false,
       customBodyRender: (value,metadata) =>
        <Switch
            color='default'
            inputProps={{ 'aria-label': 'checkbox with default color' }}
            onChange={()=>handleChangeActive(value,metadata)}
            name='checkedC'
            checked={value}
            size="small"
          />    
    }
    },{
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value) => renderActions(value)
      },
    }
   ];
   
   const options = {
    filter: false,
    viewColumns: false,
    selectableRows: false,
    download: true,
    downloadOptions: {
      filename: 'contractrequests.csv',
    },
    print: false,
    rowsPerPageOptions: [],
  }

  const [values, setValues] = useState({
    data: {
        name: '',
        apr:'',
        loan_term: ''
    },
    required: {
        name: '',
        apr:'',
        loan_term: ''
    }
  })

  const [open,setOpen]=useState(false);
  const offresRequestState = useSelector(state => state.offerReducers.save);
  const offresRequestListState = useSelector(state => state.offerReducers.list);
  const handleClose = () => {
    setOpen(false);
    props.actions.stateInit()
    stateInit()
  };

  const handleEdit = (id) => {
    let _data=offresRequestListState?.data?.data.filter(a=>a.id===id)
    if(_data.length>0){
      let data = values.data
      data.name= _data[0].name;
      data.apr= _data[0].apr;
      data.lone_term= _data[0].lone_term;
      data.id=id
      setValues({
        ...values,
        ['data']: data
      })
      setOpen(true);
    }
  };

  const loadDashboard=()=>{
    setOpen(false);
    props.actions.stateInit()
    props.actions.getContractRequestsList()
    stateInit()
  }

  const stateInit=()=>{
    const data= {
      description: '',
      valid_start: moment(new Date()).format("YYYY-MM-DD"),
      valid_end: moment(new Date()).format("YYYY-MM-DD"),
      min_score: '',
      internal_priority: ''
    };
    const required={
      description: '',
      valid_start: '',
      min_score: '',
      internal_priority: ''
    };
    setValues({
      ...values,
      ['data']: data,
      ['required']: required
  });
  }
  const handleOpen=()=>{
    setOpen(!open);
  }

  const validation = () => {
    var reqData = values['required'];
    var isValid = true;
  
    if (!values.data.hasOwnProperty("name") || (values.data.name.trim() === "" || reqData["name"] === "error")) {
        reqData["name"] = "error";
        isValid = false;
    }
  
    if (!values.data.hasOwnProperty("apr") || (values.data.apr=== "" || reqData["apr"] === "error")) {
      reqData["apr"] = "error";
      isValid = false;
    }

    if (!values.data.hasOwnProperty("loan_term") || (values.data.loan_term.trim() === "" || reqData["loan_term"] === "error")) {
      reqData["loan_term"] = "error";
      isValid = false;
    }

    setValues({
      ...values,
      'required': reqData
  });
  return isValid;
}

  useEffect(()=>{
      props.actions.offersList()
  },[props.actions])

    const handleSave=()=>{
      if(validation()){
      props.actions.offersSave(values.data);
      }
    }
    const handleChange =(name,value) => {
      var validData = "success";
      let required = values.required;
      let data = values.data
      required[name] = validData
      data[name] = value
          setValues({
              ...values,
              ['data']: data,
              ['required']: required
          });
    };

    const onHandleDialog=()=>{
      return(
        <Dialog 
          content={
          <Offers 
            handleChange={handleChange} 
            data={values} 
            handleClose={handleClose} 
            handleSave={handleSave}
            response={offresRequestState}
            loadDashboard={loadDashboard}
          />} 
          title="Offers" 
          open={open} 
          action={null} 
          handleClose={handleClose} 
        />
      )
    }

    return (
        <Container >
          {onHandleDialog()}
          <Grid container spacing={3} >
          <Grid item xs={12} sm={12} lg={12}> 
          <br/>
          <Button 
          variant="contained"
          sx={{ float:'right',marginLeft:'12px'}}
          onClick={()=>handleOpen()}
          >
           <Add sx={{fontSize:'20px'}}/> Offer
        </Button>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}> 
          
          <Offer 
          title={"Offers "}
          data={offresRequestListState?.data?.data}
          columns={columns}
          options={options}/>
            </Grid>
          </Grid>        
        </Container>
    );
  }
  const mapDispatchToProps = (dispath) => ({
    actions: bindActionCreators(ActionCreators, dispath)
  });

  export default connect(null, mapDispatchToProps)(OffersPages);