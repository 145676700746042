import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import userAuthReducers from "./userAuthReducers"
import offerReducers from "./offerReducers"

const appReducer = combineReducers({
  routerState: routerReducer,
  userAuthReducers: userAuthReducers,
  offerReducers:offerReducers
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer

