import axios from '../utils/axios.jsx';
import { commonUtils } from '../utils'
export const offersSave = async (data) => {
    try {
        let token = commonUtils.get_auth()
        let params=JSON.stringify(data);
        const response = await axios.post('/offers', params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOffersList = async () => {
    try {
        let token = commonUtils.get_auth()
        const response = await axios.get('/offers', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const offersUpdateStatus= async (data) => {
    try {
        let formData=new FormData();
        formData.append('id',data.id)
        formData.append('is_active',data.is_active)
        let token = commonUtils.get_auth()
        const response = await axios.put('/offers', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}
